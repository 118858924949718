import React, { useState, useEffect, useRef, useCallback } from "react";
import { Check, ChevronDown } from "lucide-react";

export const CustomSelect = ({
  isDarkMode = false,
  options = [],
  onToggle,
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const optionRefs = useRef([]);

  const toggleSelect = useCallback(() => setIsOpen((prev) => !prev), []);

  useEffect(() => {
    if (typeof onToggle === "function") {
      onToggle();
    }
  }, [isOpen, onToggle]);

  const handleOptionClick = useCallback(
    (option) => {
      onChange(option.value);
      setIsOpen(false);
      buttonRef.current.focus();
    },
    [onChange]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative flex flex-col items-start pointer-events-none ${
        isOpen ? "h-[300px]" : "h-auto"
      }`}
      ref={dropdownRef}
    >
      <button
        ref={buttonRef}
        onClick={toggleSelect}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-labelledby="selectButton"
        className={`px-2 py-1 rounded border flex items-center justify-between gap-1 pointer-events-auto ${
          isDarkMode
            ? "bg-neutral-800 text-white border-neutral-600"
            : "bg-white text-black border-gray-300"
        }`}
      >
        <span id="selectButton" className="sr-only">
          Select
        </span>
        <span>
          {value ? options.find((x) => x.value === value).label : null}
        </span>
        <ChevronDown
          className={`w-4 h-4 ${isOpen ? "transform rotate-180" : ""}`}
        />
      </button>
      {isOpen && (
        <ul
          role="listbox"
          aria-labelledby="selectButton"
          className={`py-1 mt-1 border rounded shadow-lg overflow-auto pointer-events-auto ${
            isDarkMode
              ? "bg-neutral-800 text-white border-neutral-600"
              : "bg-white text-black border-gray-300"
          }`}
        >
          {options.map((option, index) => (
            <li
              key={option.value}
              ref={(el) => (optionRefs.current[index] = el)}
              role="option"
              aria-selected={value && value === option.value}
              onClick={() => handleOptionClick(option)}
              tabIndex="0"
              className={`px-3 py-1 cursor-pointer flex items-center justify-between ${
                isDarkMode ? "hover:bg-neutral-700" : "hover:bg-gray-100"
              }`}
            >
              <span>{option.label}</span>
              {value && value === option.value && <Check className="w-4 h-4" />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
